<template>
   <main>
      <div class="header">
         <img src="/images/bkon_cc_hero.jpg" />
      </div>
      <section id="homedash">
         <aside>
            <article>
               <h3>Recipes</h3>
                <ul class="rrecipes">
                   <li v-for="recipe in recipes" :key="recipe.id">
                      <i class="fa" v-show="recipe.type <= 0">?</i>
                      <i v-show="recipe.type > 0" :class="'typeIcon '+ RECIPE_TYPES[recipe.type]"><img :src="'/images/typeIcons/'+RECIPE_TYPES[recipe.type]+'.png'"  /></i>
                      <router-link :to="{name:'RecipeEditor',params:{id:recipe.id}}">{{recipe.name}} <small>{{RECIPE_ACCOUNTS[recipe.account]}}</small></router-link>
                   </li>
               </ul>
                <hr style="margin-bottom: 5px">
                <router-link style="color: red" :to="{name:'RecipeManager'}">More Recipes <i class="fa fa-chevron-right"></i></router-link>
            </article>
            <article>
               <h3>Menus</h3>
               <ul class="rmenus">
                  <li v-for="menu in menus" :key="menu.id">
                     <router-link :to="{name:'MenuEditor',params:{id:menu.id}}">{{menu.description}} <small>{{RECIPE_ACCOUNTS[menu.account]}}</small></router-link>
                  </li>
               </ul>
            </article>
         </aside>

         <aside>
            <article>
               <h3>
                  Accounts
                  <a @click="createAccount()" data-toggle="modal" data-target="#accountModal" class="btn btn-default pull-right"><i class="fa fa-plus-circle"></i> New Account</a>
                  <p class="clear clearfix"></p>
               </h3>
               <ul class="list-group account-list">
                 <li v-for="account in accounts" :key="account.id" class="list-group-item">
                    <a  ><span @click="editAccount(account)" style="cursor:pointer" data-toggle="modal" data-target="#accountModal">{{account.name}}</span></a>
                    <a @click="viewAccountUsers(account)" href data-toggle="modal" data-target="#usersModal" class="btn "><i class="fa fa-users"></i></a>
                    <a :href="'/?fsKOoid8_='+account.id" target="_blank" class="btn">Switch To</a>
                 </li>
               </ul>
            </article>
            <article>
               <h3>Software</h3>
               <ul class="list-group">
                  <li v-for="spackage in softwarePackages" :key="spackage.key" class="list-group-item">
                     <a :href="spackage.url" target="_blank">{{spackage.name}} <small>v: {{spackage.version}}</small></a>
                  </li>
               </ul>
            </article>
         </aside>

         <aside>
            <article class="resource-center">
               <h3>Resource Center</h3>
               <ul class="list-group">
                  <li v-for="(post,$index) in rcPosts " :key="$index" class="list-group-item">
                     <a class="" target="_blank" :href="post.url">
                        <div class='titleBar'>
                           <img :src="post.image" />
                           <p class="desc"><span class="title">{{post.title}}</span><span>{{post.description}}</span></p>
                        </div>
                     </a>
                  </li>
               </ul>
               <hr style="margin-bottom: 5px">
               <a style="color: red" target="_blank" href="http://rc-cs.franke.com/product/bkon">More Posts <i class="fa fa-chevron-right"></i></a>
            </article>
            <article>
               <h3>Social Media</h3>
               <link rel="stylesheet" type="text/css" href="https://cdn.jsdelivr.net/gh/vaakash/socializer@2f749eb/css/socializer.min.css">
               <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.15.3/css/all.css">
               <div class="socializer a sr-64px sr-drop sr-zoom sr-vertical sr-font-lg sr-icon-white sr-sw-icon-1 sr-pad">
                  <span class="sr-facebook sr-text-in"><a href="https://www.facebook.com/bkonbrew/?ref=bookmarks" target="_blank" title="Facebook"><i class="fab fa-facebook-f"></i><span class="text">Facebook</span></a></span>
                  <span class="sr-email sr-text-in"><a href="mailto:info@bkonbrew.com" target="_blank" title="Email"><i class="fa fa-envelope"></i><span class="text">Email</span></a></span>
                  <span class="sr-instagram sr-text-in"><a href="https://www.instagram.com/bkonbrew/" target="_blank" title="Instagram"><i class="fab fa-instagram"></i><span class="text">Instagram</span></a></span>
                  <span class="sr-youtube sr-text-in"><a href="https://www.youtube.com/channel/UCsJFpDksdTcCSbGt2FeV37Q?view_as=subscriber" target="_blank" title="Youtube"><i class="fab fa-youtube"></i><span class="text">Youtube</span></a></span>
               </div>
               <div class="socializer-tehgol a sr-64px sr-drop sr-zoom sr-vertical sr-font-lg sr-icon-white sr-sw-icon-1 sr-pad">
                  <span class="sr-facebook sr-text-in">@bkonbrew</span>
                  <span class="sr-email sr-text-in">info@bkonbrew.com</span>
                  <span class="sr-instagram sr-text-in">@bkonbrew</span>
                  <span class="sr-youtube sr-text-in">@bkonbrew</span>
               </div>
            </article>
         </aside>
      </section>


      <h2 id="system_functions" v-if="isSystemUser">System Functions</h2>
      <section id="homedash" v-if="isSystemUser" class="system-functions">
         <aside>
            <article class="languages">
               <h3>Language Management</h3>
               <ul class="list-group">
                  <li v-for="language in languages" :key="language.id" class="list-group-item">
                     <a :href="'https://legacy.bkon-craftcloud.com/language/update/'+language.id+'?apiKey='+userToken" target="_blank">{{language.name}} <small>{{language.config_lab_id}}</small></a>
                  </li>
              </ul>
            </article>
         </aside>
         <aside>
            <article>
               <h3>Service Menu</h3>
               <ul class="rmenus">
                  <li v-for="recipe in serviceMenuRecipes" :key="recipe.id" class="rrecipe">
                     <a :href="'https://legacy.bkon-craftcloud.com/serviceMenuFile/update/'+recipe.id+'?apiKey='+userToken" target="_blank">{{recipe.name}} <small>{{recipe.active ? 'active' : ''}}</small></a>
                  </li>
              </ul>
            </article>
         </aside>
         <aside>
            <article>
               <h3>Advanced Functions</h3>
               <ul class="list-group">
                  <li class="list-group-item"><a :href="'https://legacy.bkon-craftcloud.com/configLab?apiKey='+userToken" target="_blank">Config Lab</a></li>
                  <li class="list-group-item"><a :href="'https://legacy.bkon-craftcloud.com/compile/copyMenu?apiKey='+userToken" target="_blank">Legacy Copy Menus</a></li>
               </ul>
            </article>
         </aside>
      </section>
   </main>

   <BootstrapModal @modalClosed="resetAccount()" @modalSavedAction="saveAccount()" :title="'New Account'" :modalId="'accountModal'" saveButton="Save Account">
      <BasicForm v-if="showAccountModal" :fields="newAccountFields" :model="newAccount"></BasicForm>
   </BootstrapModal>
   <!-- <BootstrapModal @modalClosed="resetUser()" @modalSavedAction="saveUser()" :title="'New User'" :modalId="'userModal'">

   </BootstrapModal> -->
   <BootstrapModal  :title="'Account Users ('+currentAccount.name+')'" :modalId="'usersModal'" saveButton="">
      <div v-if="showUserModal == true" class="editUser">
         <h3>{{userModalModel.id ? 'Edit' : 'Create'}} User</h3>
         <BasicForm :fields="userFields" :model="userModalModel"></BasicForm>
         <button @click="showUserModal = false" class="btn btn-sm pull-right">Cancel</button>
         <button @click="saveUser()" class="btn btn-sm pull-right btn-primary">Update User</button>
         <hr style="clear: both"  />
      </div>

      <a @click="createUser()" class="btn btn-sm pull-right"><i class="fa fa-plus-circle"></i> User</a>
      <h3>All Users</h3>
      <table class="table">
         <thead>
            <tr>
               <th v-for="col in ['name','email','primaryLanguage','']">{{col}}</th>
            </tr>
         </thead>
         <tbody>
            <tr v-for="user in currentAccountUsers">
               <td v-for="col in ['name','email','primaryLanguage','']">
                  <span v-if="col == ''"><a @click="editUser(user)" class="btn">Edit</a></span>
                  <span v-else-if="col == 'primaryLanguage'">{{user[col] == '1' ? 'English' : 'Other'}}</span>
                  <span v-else>{{user[col]}}</span>
               </td>
            </tr>
         </tbody>
      </table>
   </BootstrapModal>

</template>

<script lang="ts">
   import { Options, Vue } from 'vue-class-component';
   import Globals from '../Globals';
   import { API_BASE_URL } from '../environment'
   import { Menu, Account,User } from '../Types';
   import Recipe from '../models/Recipe';
   import Axios from 'axios';
   import BootstrapModal from '../components/BootstrapModal.vue';
   import BasicForm from '../components/BasicForm.vue'
   let newAccount:any = { name:'', type:'user', user: {name:'',email:'',password:''}};

   const unitsOptions = [{value:0,text:'Imperial'},{value:1,text:'Metric'}];
   const languagesOptions = [{value:1,text:'English'},{value:3,text:'Chinese'},{value:5,text:'Deutsh'},{value:7,text:'Turkish'},{value:8,text:'South Korean'}];

   @Options({components:{BootstrapModal,BasicForm},emits:['accountTypeFound']})
   export default class Dashboard extends Vue {

      recipes:Array<Recipe> = [];
      menus:Array<Menu> = [];
      accounts:Array<Account> = [];
      softwarePackages:Array<any> = [];
      rcPosts:Array<any> = [];
      RECIPE_ACCOUNTS:any = {};
      languages:Array<any> = [];
      serviceMenuRecipes:Array<any> = [];

      isSystemUser:boolean = false;

      showAccountModal:boolean = false;
      newAccount:any = newAccount;
      newAccountFields:Array<any> = [];
      showUserModal:boolean = false;
      userModalModel:User = new User;
      userFields:Array<any> = [];
      currentAccount:Account = new Account;
      currentAccountUsers:Array<User> = [];
      userToken:string = '';

      //computed
      get RECIPE_TYPES() { return Recipe.types; }

      //methods
      viewAccountUsers(account:Account) {
         this.currentAccount = account;
         Axios.get(`${API_BASE_URL}/users?account=${account.id}`,Globals.axiosConf)
         .then(({data})=>{
            this.currentAccountUsers = data;
         });
      }
      createUser() { this.editUser(new User); }
      editUser(user:User) {
         this.userModalModel = user;
         this.userFields = ['name','email','password','primaryLanguage','primaryUnits'].map((key:string)=>{
            return {
               key: key,
               placeholder:key,
               type:key =='email' ? 'email' : key == 'password' ? 'password' : ['primaryUnits','primaryLanguage'].indexOf(key) > -1 ? 'select' : 'text',
               label:key[0].toUpperCase()+key.slice(1).replace('_',' '),
               options:key == 'primaryUnits' ? unitsOptions : key == 'primaryLanguage' ? languagesOptions : null,
               //@ts-ignore
               model:user[key],
               //@ts-ignore
               update(val:string) { user[key] = val; },

            }
         })
         this.showUserModal = true;
      }
      resetUser() { this.showUserModal = false; }
      saveUser() {
         let userData = {...this.userModalModel,account:this.currentAccount.id};
         Axios[userData.id ? 'put' : 'post'](`${API_BASE_URL}/users${userData.id ? '/'+userData.id : ''}`,userData,Globals.axiosConf)
         .then(({data})=>{
            this.showUserModal = false;
            if(!userData.id) this.currentAccountUsers.push(new User(data));
            this.$forceUpdate();
         }).catch(e=>alert('user not saved'));
       }
      resetAccount() {  this.showAccountModal = false; }
      createAccount() {
         newAccount = { name:'', type:'user', user: {name:'',email:'',password:''}};
         this.newAccount = newAccount;
         this.newAccountFields = [
            {
               key: 'name',
               placeholder:'Account Name',
               type: 'text',
               label: 'Account Name',
               update(val:string) {  newAccount.name = val; }
            },
            {
               key: 'type',
               placeholder:'',
               type: 'select',
               label: 'Account type',
               options:[{value:'user',text:'User'},{value:'distro',text:'Distibutor'},{value:'system',text:'System'},{value:'import',text:"Import"}],
               update(val:string) { newAccount.type = val; }
            },
            {
               key: 'name',
               placeholder:'John Appleseed',
               type: 'text',
               label: 'First User\'s Name',
               model:this.newAccount.user.name,
               update(val:string) { newAccount.user.name = val; }
            },
            {
               key: 'email',
               placeholder:'john@appleseed.com',
               type: 'email',
               label: 'First User\'s Email',
               model:this.newAccount.user.email,
               update(val:string) { newAccount.user.email = val; }
            },
            {
               key: 'password',
               placeholder:'top secret password',
               type: 'password',
               label: 'First User\'s Password',
               model: this.newAccount.user.password,
               update(val:string) { newAccount.user.password = val; }
            }
         ];
         this.showAccountModal = true;
      }
      editAccount(account:Account) {
         newAccount = account;
         this.newAccount = newAccount;
         this.newAccount.user = {email:false};
         this.newAccountFields = ['name','type'].map((ky:string)=>{
               return {
                  key: ky,
                  placeholder:ky,
                  type: 'text',
                  label: ky[0].toUpperCase() + ky.slice(1),
                  update(val:string) {  newAccount[ky] = val; },
                  model:newAccount[ky]
               }
            });
            this.showAccountModal = true;
      }
      saveAccount() {
         let accountData:any = this.newAccount.id ? {...this.newAccount} : {...this.newAccount,control_account: Globals.me.account,import:0,sharing:1};
         delete accountData.user;

         Axios[accountData.id ? 'put' : 'post'](`${API_BASE_URL}/accounts${accountData.id ? '/'+accountData.id : ''}`,accountData,Globals.axiosConf)
         .then(({data})=>{
            if(this.newAccount.user.email) {
               let userData = {...this.newAccount.user,account:data.id,can_get_menu: 1,can_manage_recipe: 1,can_make_menu: 1,can_approve_recipe:1,can_admin:1};
               Axios.post(`${API_BASE_URL}/users`,userData,Globals.axiosConf)
               .then(({data})=>{
                  //@ts-ignore
                  window.jQuery('#accountModal').modal('hide')
                  this.accounts.push(new Account(data));
                  this.$forceUpdate();
               }).catch(e=>alert('new account user not saved'));
            } else {
               //@ts-ignore
               window.jQuery('#accountModal').modal('hide');
               this.$forceUpdate();
            }
         }).catch(e=>alert('new account not saved'));
      }


      //lifecycle
      async created() {
         if(!Globals.isAuthenticated().token) return this.$router.push({name:'Login'})
         await Globals.isMeReady().catch(e=>{ alert('Can\'t find you, please logout and try again')});

         this.RECIPE_ACCOUNTS[Globals.me.account] = Globals.me.account_name;
         this.isSystemUser = Globals.me.rootAccount.type == 'system';
         this.userToken = Globals.me.auth_key;

         //@ts-ignore
         this.$root.updateSystemUser(Globals.me.rootAccount.type);

         //check system functions scroll
         if(window.location.href.indexOf('#system_functions') > -1) setTimeout(()=>{ window.scrollTo(0,1500)}, 500);

         //get recipes
         Axios.get(`${API_BASE_URL}/recipes?account=${Globals.currentAccountId}&columns=id,name,type`,Globals.axiosConf)
         .then(({data})=>{
            this.recipes = data.filter((rec:any)=>rec.type > 0).map((rec:any)=>new Recipe(rec)).slice(0,5);
         }).catch(e=>{ console.error(e); });

         //get menus
         Axios.get(`${API_BASE_URL}/compiles?account=${Globals.currentAccountId}&columns=id,description,account`,Globals.axiosConf)
         .then(({data})=>{
            this.menus = data;
         }).catch(e=>{ console.error(e);});

         //get Accounts
         Axios.get(`${API_BASE_URL}/accounts?control_account=${Globals.currentAccountId}&columns=id,name,control_account,type`,Globals.axiosConf)
         .then(({data})=>{
            this.accounts = data.map((acct:any)=>new Account(acct));
         }).catch(e=>{ console.error(e);});
         Axios.get(`${API_BASE_URL}/accounts/${Globals.currentAccountId}`,Globals.axiosConf)
         .then(({data})=>{
            this.accounts.push(new Account(data));
         }).catch(e=>{ console.error(e);});

         //get softwarePackges
         Axios.get(`https://craftcloud-softwares.azurewebsites.net/software-packages`,{headers:{Authorization:"7a7c8582-9e9f-4f32-bb46-28a1dd3ed341"}})
         .then(({data})=>{
            this.softwarePackages = data;
         }).catch(e=>{ console.error(e);});

         //get resource center post
         Axios.get(`http://rc-cs.franke.com/site/post-by-tag/3?multi&tag&json`)
         .then(({data})=>{
            this.rcPosts = data.sort(() => Math.random() - 0.5).slice(0,15);
         }).catch(e=>{ console.error(e);});

         //get lanugages
         Axios.get(`${API_BASE_URL}/languages`,Globals.axiosConf)
         .then(({data})=>{
            this.languages = data;
         }).catch(e=>console.error('no languages'));

         //get service menu
         Axios.get(`${API_BASE_URL}/service-menu-files?columns=id,name`,Globals.axiosConf)
         .then(({data})=>{
            this.serviceMenuRecipes = data;
         }).catch(e=>console.error('no service menus'));
      }
   }
</script>
<style lang="less" scoped>
   main {
      display: flex;
      flex-direction: column;

      h2 { margin: 0 15px}

      div.header {
         width: 100%;
         img {
            width: 100%;
         }
      }
   }
   section {
      display: flex;
      padding-top: 30px;

      aside {
         width: 33.33%;
         padding: 1.25rem;
         display: flex;
         flex-direction: column;
         justify-content: center;
         align-items: center;

         article {
            height: 30vh;
            min-height: 400px;
            max-height: 600px;
            padding: 0% 5%;
            width: 100%;
            border-radius: .25rem;
            border: 1px solid rgba(0,0,0,.125);
            padding: 1.25rem;
            margin: 30px 0;
            position: relative;

            h3 {
               margin-bottom: 5px;
               padding-bottom: 5px;
            }
         }
      }
   }

   .socializer-tehgol {
      display: flex;
      flex-direction: column;
      position: absolute;
      top: 55px;
      left: calc( 0px + 70px );
      margin-left: 30px;

      z-index: 9999;
      span {
         height: 60px;
         font-size: 30px;
      }
   }

   .resource-center {
      ul {
         height: 80%;
         overflow: auto;

         li {
            a {
               color: #555;
               div.titleBar {
                  display: flex;

                  img {
                     width: 75px;
                     margin-right: 30px;
                  }
               }
               p {
                  display: flex;
                  flex-direction: column;

                  .title { font-weight: bold;}
               }
            }
         }
      }
   }

   .account-list {

         li {
            display: flex;
            justify-content: space-between;

            a:first-child {
               flex: 1;
            }
         }
   }

   .system-functions {
      aside {

         article {
            height: 80vh;
            max-height: 700px;
         }
      }
      .languages {

      }
   }

   .editUser {
      padding-bottom: 60px;

      hr { margin-top: 60px; }
   }
</style>
