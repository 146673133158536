<template>
   <div :id="modalId" class="modal fade">
     <div class="modal-dialog">
       <div class="modal-content">
         <div class="modal-header">
           <button type="button" class="close" data-dismiss="modal"><span aria-hidden="true">&times;</span><span class="sr-only">Close</span></button>
           <h4 class="modal-title">{{title}}</h4>
         </div>
         <div class="modal-body">
           <slot></slot>
         </div>
         <div class="modal-footer">
           <button type="button" class="btn btn-default" data-dismiss="modal">Close</button>
           <button v-if="saveButton" @click="$emit('modalSavedAction')" type="button" class="btn btn-primary">{{saveButton}}</button>
         </div>
       </div><!-- /.modal-content -->
     </div><!-- /.modal-dialog -->
   </div><!-- /.modal -->
</template>

<script lang="ts">
   import { Options, Vue } from 'vue-class-component';
   // import Globals from '../Globals';
   // import { API_BASE_URL } from '../environment'

   @Options({
      props: {
         modalId:String,
         title:String,
         saveButton:String
      }
   })
   export default class BootstrapModal extends Vue {
      modalId!:string; //@Prop
      saveButton!:string; //@Prop

      mounted() {
         let $this = this;
         let selector:string = '#'+this.modalId;
         //@ts-ignore
         jQuery(selector).on('hide.bs.modal',function(evt){  $this.$emit('modalClosed',$this.modalId);  })
      }
   }
</script>

<style lang="less">
   .form-input-group {
      margin: 20px 0;
   }
</style>
