<template>
   <div v-for="(field,fieldIndex) in fields" class="form-input-group">
      <label :for="'acct'+fieldIndex">{{field.label}}</label>
      <textarea @change="updateVar($event,field)" v-if="field.type =='textarea'" v-model="fieldModels[fieldIndex]"  :id="'acct'+fieldIndex" class="form-control" :placeholder="field.placeholder"></textarea>
      <input @change="updateVar($event,field)" v-if="field.type != 'select' && field.type != 'textarea'" v-model="fieldModels[fieldIndex]" :type='field.type' :id="'acct'+fieldIndex" class="form-control" :placeholder="field.placeholder" />
      <select @change="updateVar($event,field)" v-if="field.type == 'select'" v-model="fieldModels[fieldIndex]" class="form-control"  :id="'acct'+fieldIndex">
         <option v-for="option in field.options" :value="option.value">{{option.text}}</option>
      </select>
      <!-- <p class="text-danger"></p> -->
   </div>
</template>
<script lang="js">
export default {
   props: {
      fields:[Object],
      model:Object
   },
   emits:['update:fields','update:model'],
   created() {
         this.fieldModels = this.fields.map(field=>field.model || this.model[field.key]);
   },
   data() {
      return {
         fieldModels: []
      }
   },
   computed: {

   },
   methods: {
      updateVar(evt,field) {
         field.update(evt.target.value);
      }
   }
}
</script>
