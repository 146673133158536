import RecipeClass from './models/Recipe';


export class User {
   id:number = 0;
   account:number = 0;
   auth_key:string = '';
   account_name:string ='';
   rootAccount:Account = new Account;
   email:string = '';
   name:string = '';
   primaryUnits:number = 0;
   primaryLanguage:number= 0;

   constructor(opts:any = {}) {
         this.id = opts.id || 0;
         this.account = opts.account || 0;
         this.auth_key = opts.auth_key || '';
         this.account_name = opts.account_name ||'';
         this.rootAccount = new Account(opts.rootAccount);
         this.email = opts.email || '';
         this.name = opts.name || '';
         this.primaryUnits = opts.primaryUnits || 0;
         this.primaryLanguage = opts.primaryLanguage || 0;
   }
}


export class Menu {

   id:number = 0;
   date:Date = new Date();
   config:string = '';
   recipes:Array<any> = [];
   account:number = 0;
   description:string = '';
   fromapp:number = 0;
   recipeCount:number = 0;

   constructor(opts:any = {}) {
      this.id = opts.id || 0;
      this.date = opts.date || new Date();
      this.config = opts.config || '';
      this.recipes = opts.recipes || [];
      this.account = opts.account || 0;
      this.description = opts.description || '';
      this.fromapp = opts.fromapp || 0;
      this.recipeCount = opts.recipeCount || 0;
   }
}

export class Account {

   id:number = 0;
   name:string = '';
   type:string = '';
   sync_number:string = '';
   oa:string = '';
   image:string = '';
   color:string = '';
   import:number = 0;
   sharing:number = 0;
   sending:number = 0;
   control_account:number = 0;

   constructor(opts:any = {}) {
      this.id = opts.id || 0;
      this.name = opts.name || '';
      this.type = opts.type || '';
      this.sync_number = opts.sync_number || '';
      this.oa = opts.oa || '';
      this.image = opts.image || '';
      this.color = opts.color || '';
      this.import = opts.import || 0;
      this.sharing = opts.sharing || 0;
      this.sending = opts.sending || 0;
      this.control_account = opts.control_account || 0;
   }
}

export class AuthResponse {
   token:string ='';
   success:boolean = false;
   user_id:number = 0;
   email:string = '';
   account_name:string = '';
   control_account_id:number = 0;
   privileges:any = {};
}

export const Recipe = RecipeClass;
