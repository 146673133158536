
   import { Options, Vue } from 'vue-class-component';
   // import Globals from '../Globals';
   // import { API_BASE_URL } from '../environment'

   @Options({
      props: {
         modalId:String,
         title:String,
         saveButton:String
      }
   })
   export default class BootstrapModal extends Vue {
      modalId!:string; //@Prop
      saveButton!:string; //@Prop

      mounted() {
         let $this = this;
         let selector:string = '#'+this.modalId;
         //@ts-ignore
         jQuery(selector).on('hide.bs.modal',function(evt){  $this.$emit('modalClosed',$this.modalId);  })
      }
   }
