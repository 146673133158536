import Globals from '../Globals';
import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Dashboard from '../views/Dashboard.vue'
import Login from '../views/Login.vue'
import RequestPassword from '../views/RequestPassword.vue'
import ResetPassword from '../views/ResetPassword.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Dashboard',
    component: Dashboard
  },
  {
    path: '/request-password',
    name: 'RequestPassword',
    component: RequestPassword
  },
  {
    path: '/reset-password/:token',
    name: 'ResetPassword',
    component: ResetPassword
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/logout',
    name: 'Logout',
    redirect: (to)=>{
         Globals.logout();
         return '/login';
      }
   },

     {
       path: '/support',
       name: 'Support',
       component: () => import(/* webpackChunkName: "about" */ '../views/Support.vue')
   },
   {
     path: '/recipes/:id/update',
     name: 'RecipeEditor',
     component: () => import(/* webpackChunkName: "about" */ '../components/RecipeEditor.vue')
   },
   {
     path: '/recipes',
     name: 'RecipeManager',
     component: () => import(/* webpackChunkName: "about" */ '../views/RecipeManager.vue')
  },
  {
    path: '/recipes/:id/edit',
    name: 'RecipeEditor',
    component: () => import(/* webpackChunkName: "about" */ '../views/RecipeManager.vue')
  }
   ,{
     path: '/menus',
     name: 'MenuManager',
     component: () => import(/* webpackChunkName: "about" */ '../views/MenuManager.vue')
   },
   {
     path: '/menus/:id/edit',
     name: 'MenuEditor',
     component: () => import(/* webpackChunkName: "about" */ '../views/MenuManager.vue')
   },
   {
     path: '/user-settings/:id',
     name: 'UserSettings',
     component: () => import(/* webpackChunkName: "about" */ '../views/UserManagement.vue')
   }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
