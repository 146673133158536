<template>
<header id="masthead" class="site-header" role="banner">

   <nav id="site-navigation" class="header clear" role="navigation">
      <router-link :to="'/'" title="bkon" class="logo"><img :src="this.logoSrc" alt="bkon"></router-link>
         <div class="menu-mainmenu-container">
            <ul id="menu-mainmenu" class="nav-menu">

               <li id="menu-item-13" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-16">
                  <router-link :to="{name:'RecipeManager'}">Recipes</router-link>
               </li>
               <li id="menu-item-14" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-15">
                  <router-link :to="{name:'MenuManager'}">Menus</router-link>
               </li>
               <li id="menu-item-16" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-15">
                  <router-link :to="{name:'UserSettings',params:{id:'me'}}">My Settings</router-link>
               </li>
               <li id="menu-item-17" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-15">
                  <router-link :to="{name:'Support'}">Support</router-link>
               </li>
               <li v-if="isSystemUser" id="menu-item-18" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-15">
                  <a href="/#system_functions" class=" dropdown-toggle">System Functions</a>
                  <!-- <ul class="system-dropdown dropdown-menu">
                     <li><a href="#" class="dropdown-item">One</a></li>
                  </ul> -->
               </li>
               <li id="menu-item-19" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-19">
                  <router-link :to="{name:'Logout'}">Logout <small>({{myEmail}})</small></router-link>
               </li>
            </ul>
         </div>
   </nav><!-- #site-navigation -->
   <p class="clearfix"></p>
</header>
<router-view  />
</template>

<script lang="ts">
   import { Vue } from 'vue-class-component';
   // import logo from '@/assets/logo.png';
   import { User } from './Types';
   import global from './Globals';

   export default class App extends Vue {

         isSystemUser:boolean = false;
         myEmail:string = '???';

         //computed
         get logoSrc() { return require('@/assets/logo.png'); }

         updateSystemUser(type:string) {
            this.isSystemUser =  type == 'system';
         }
         updateMyEmail(email:string) {
            this.myEmail = email;
         }


         checkUrlForAccount() {
            let accountKey = 'fsKOoid8_=';
            if(window.location.href.indexOf(accountKey)>-1) {
               let accountId:number = parseInt(window.location.href.split(accountKey)[1].split('&')[0]);
               global.masqueradeAsAccount(accountId);
            }
         }

         //lifecycle
         created() {
            this.checkUrlForAccount();
            global.getMe().then(me=>{
               this.myEmail = me.email;
            }).catch(e=>console.log('cannot get current user'));
         }

   }
</script>

<style lang="less">

</style>
