
   import { Vue } from 'vue-class-component';
   // import logo from '@/assets/logo.png';
   import { User } from './Types';
   import global from './Globals';

   export default class App extends Vue {

         isSystemUser:boolean = false;
         myEmail:string = '???';

         //computed
         get logoSrc() { return require('@/assets/logo.png'); }

         updateSystemUser(type:string) {
            this.isSystemUser =  type == 'system';
         }
         updateMyEmail(email:string) {
            this.myEmail = email;
         }


         checkUrlForAccount() {
            let accountKey = 'fsKOoid8_=';
            if(window.location.href.indexOf(accountKey)>-1) {
               let accountId:number = parseInt(window.location.href.split(accountKey)[1].split('&')[0]);
               global.masqueradeAsAccount(accountId);
            }
         }

         //lifecycle
         created() {
            this.checkUrlForAccount();
            global.getMe().then(me=>{
               this.myEmail = me.email;
            }).catch(e=>console.log('cannot get current user'));
         }

   }
