import Axios from 'axios';
import { BKON_API_TOKEN, API_BASE_URL } from './environment';
import { User,AuthResponse }  from "../src/Types";

const LS_AUTH_KEY = 'cciii_user';


export class Globals {
   me:User = new User();
   currentAccountId:number = 0;
   celciusMode:boolean = false;

   constructor() {

      if(this.isAuthenticated().token) this.getMe().catch(e=>{});
   }

   get axiosConf() { return {headers: {Authorization: this.me.auth_key}}}


   authenticate(user:Object) {
      return new Promise((resolve,reject)=>{
         let authUser:boolean|void = window.localStorage.setItem(LS_AUTH_KEY,JSON.stringify(user));
         this.getMe()
         .then(axiosResponse=>{
            resolve(authUser);
         })
         .catch(e=>{
            reject(e);
         });
      })
   }
   logout() {
      window.localStorage.removeItem('userToken');
      window.localStorage.removeItem(LS_AUTH_KEY);
      this.me = new User();
      this.currentAccountId = 0;
   }
   getMe():Promise<User> {
      return new Promise((resolve,reject)=> {
         if(this.me.id > 0) return resolve(this.me); //return if already have me

         //check if authenticated and get token
         let auth = this.isAuthenticated();
         if(!auth.token) return reject('no token');

         //get full user details
         Axios.get(`${API_BASE_URL}/users/me`,{headers:{ Authorization: auth.token}})
         .then(({data})=>{
            this.me = new User({auth_key:auth.token,account_name:auth.account_name,...data});
            if(this.currentAccountId == 0) this.currentAccountId = this.me.account;
            if(data.primaryUnits) this.celciusMode = true;
            //@ts-ignore
            window.celciusMode = this.celciusMode;
            resolve(this.me);
         })
         .catch(e=>{
            console.log(e,e.response);
            reject('token not correct or other network');
         })
      });
   }

   masqueradeAsAccount(accountId:number) {
      this.currentAccountId = accountId;
   }

   isAuthenticated():AuthResponse {
      let authUser:string|null = window.localStorage.getItem(LS_AUTH_KEY);
      try { return JSON.parse(authUser || '{}'); } catch(e) { return new AuthResponse; }
   }
   isMeReady():Promise<User> {
      let $this = this;
      return new Promise(async (resolve,reject)=>{
         let interval = setInterval(()=>{
            if ($this.me.id > 0) {
               clearInterval(interval);
               resolve($this.me);
            }
         },200);
         setTimeout(()=>{
            clearInterval(interval);
            reject('timed out');
         },30000);
      })
   }
}

export const global = new Globals();
export default global;
