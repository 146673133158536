import './Globals'
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import './assets/css/main.less'

createApp(App).use(router).mount('#app')


//DATES
//@ts-ignore
Date.prototype.mysql = function () {
   var mm = this.getMonth() + 1; // getMonth() is zero-based
   var dd = this.getDate();
   var mmm = this.getMinutes();
   var hh = this.getHours();
   var ss = this.getSeconds();


   return [this.getFullYear(),
   (mm > 9 ? '' : '0') + mm,
   (dd > 9 ? '' : '0') + dd
   ].join('-') + " " +
      [
         (hh > 9 ? '' : '0') + hh,
         (mmm > 9 ? '' : '0') + mmm,
         (ss > 9 ? '' : '0') + this.getSeconds()
      ].join(':');
};
//@ts-ignore
Date.prototype.simpleDay = function () {
   var days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
   var months = ['January', 'Feburary', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
   return months[this.getMonth()] + " " + this.getDate() + ", " + this.getFullYear();
}
//@ts-ignore
Date.prototype.monthDayFullYear = function () {
   var days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
   var months = ['January', 'Feburary', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
   return (1 + this.getMonth() ) + "/" + this.getDate() + "/" + this.getFullYear();
}

//Arrays
//@ts-ignore
Array.prototype.toObjectWithKey = function (key) {
   let array = this;
   const initialValue = {};
   return array.reduce((obj, item) => {
      return {
         ...obj,
         [item[key]]: item,
      };
   }, initialValue);
};
//@ts-ignore
Array.prototype.toObjectWithKeyAndArray = function (key) {
   let array = this;
   const initialValue = {};
   return array.reduce((obj, item) => {
      if (!obj[item[key]]) obj[item[key]] = [];
      obj[item[key]].push(item);
      return obj;
   }, initialValue);
};
