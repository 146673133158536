<template>
<div id="cl-wrapper" class="login-container">
   <div class="middle-login">
      <div class="block-flat">
         <div class="header">
            <h3 class="text-center"><img class="logo-img" :src="this.logoSrc" alt="logo"></h3>
         </div>
         <div>
            <form @submit="logUserIn($event)" style="margin-bottom: 0px !important;" id="login-form" class="form-vertical" action="#" method="post">
               <div class="content">
                  <h4 class="title">Login Access</h4>
                  <div class="form-group">
                     <div class="col-sm-12" style="padding: 0 0px;">
                        <div class="form-group">
                           <div class="input-group"><span class="input-group-addon">@</span><input v-model="this.email" placeholder="Email Address" class="form-control" name="LoginForm[username]" id="LoginForm_username" type="text"></div>
                              <p id="LoginForm_username_em_" style="display:none" class="help-block"></p>
                           </div>
                        </div>
                     </div>
                     <div class="form-group">
                        <div class="col-sm-12" style="padding: 0px;">
                           <div class="form-group">
                              <div class="input-group"><span class="input-group-addon"><i class="fa fa-lock"></i></span><input v-model="this.password" placeholder="Password" class="form-control" name="LoginForm[password]" id="LoginForm_password" type="password"></div>
                                 <p id="LoginForm_password_em_" v-show='this.errMessage' class="help-block">{{this.errMessage}}</p>
                              </div>
                           </div>
                        </div>

                     </div>
                     <div class="foot">
                        <!--<a class="pull-left" href="/site/registerSoftware">Software Registration</a>-->
                        <router-link class="pull-left" :to="{name:'RequestPassword'}">Forgot Password</router-link>
                        <button class="btn btn-primary" type="submit" name="yt0">Login</button> </div>
            </form>
         </div>
      </div>
      <div class="text-center out-links"><a href="#">© {{ this.currentYear }} BKON Brew | Management System </a></div>
   </div>
</div>
</template>
<script lang="ts">
import Globals from '../Globals';
import { Options, Vue } from 'vue-class-component';
import Axios from 'axios';
import { API_BASE_URL, BKON_API_TOKEN } from '../environment'

@Options({emits:[]})
export default class Login extends Vue {

   email:String = '';
   password:String = '';
   errMessage:String = '';



   //computed
   get logoSrc() { return require('@/assets/logo.png'); }
   get currentYear() {
      return new Date().getFullYear();
   }

   //methods
   async logUserIn(evt:Event) {
      evt.preventDefault();
      let {email,password} = this;
      Axios.post(`${API_BASE_URL}/users/auth`,{email,password},{headers: { Authorization: BKON_API_TOKEN }})
      .then(async authResponse=>{
         Globals.authenticate(authResponse.data);
         //@ts-ignore
         this.$root.updateMyEmail(email);

         await this.$router.push({name:'Dashboard'}).catch(e=>{});
      })
      .catch(e=>{
         console.error(e,888);
         if(e.response && e.response.data) this.errMessage = e.response.data.failure;
      });
   }

   //lifecycle
   created() {
      document.body.classList.add('bgColor');
   }
   beforeUnmount() {
      document.body.classList.remove('bgColor');
   }
}
</script>
<style lang="less">
body.bgColor {
    background-color: #3a3832 !important;

    header { display: none;}

    .content {
      box-shadow: none;
      max-width: 100%;
   }

   .login-container .block-flat .content .title { color: #3a3832; }
}
</style>
