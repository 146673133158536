<template>
    <div id="cl-wrapper" class="login-container">
       <div class="middle-login">
          <div class="block-flat">
             <div class="header">
                <h3 class="text-center"><img class="logo-img" :src="this.logoSrc" alt="logo"></h3>
             </div>
             <div style="padding: 30px">
                <h2>Forgot Password</h2>
                <p>Don't remember your password? Enter the email address you use for the site, and a tempory password will be e-mailed to you. You can change it after logging in successfully.</p>
                <br /><br />
                <form @submit="sendPasswordResetToken($event)" action="" method="post">
    <div class="input-group">                        
        <span class="input-group-addon"><i class="fa fa-lock"></i></span>
        <input v-model="this.email" type="email" name="email" placeholder="Email Address" class="form-control">
        <div class="input-group-btn"><input type="submit" value="Reset Password" class="btn btn-primary"></div>
    </div>                        
    </form>
    <p>{{ this.errMessage }}</p>
             </div>
             
          </div>
          <div class="text-center out-links"><a href="#">© {{ this.currentYear }} BKON Brew | Management System </a></div>
       </div>
    </div>
    </template>
    <script lang="ts">
    import Globals from '../Globals';
    import { Options, Vue } from 'vue-class-component';
    import Axios from 'axios';
    import { API_BASE_URL, BKON_API_TOKEN } from '../environment'
    
    @Options({emits:[]})
    export default class RequestPassword extends Vue {
    
       email:String = '';
       errMessage:String = '';
    
    
    
       //computed
       get logoSrc() { return require('@/assets/logo.png'); }
       get currentYear() {
          return new Date().getFullYear();
       }
    
       //methods
       async sendPasswordResetToken(evt:Event) {
            evt.preventDefault();
            Axios.post(`${API_BASE_URL}/users/password-request-temp`,{email: this.email},{headers: { Authorization: BKON_API_TOKEN }})
            .then(async authResponse=>{
                this.errMessage = "Check your email for a temporary password.";
            })
            .catch(e=>{
                this.errMessage = "Request failed. Please ensure your email is correct and try again.";
            })

       }
    
       //lifecycle
       created() {
          document.body.classList.add('bgColor');
       }
       beforeUnmount() {
          document.body.classList.remove('bgColor');
       }
    }
    </script>
    <style lang="less">
    body.bgColor {
        background-color: #3a3832 !important;
    
        header { display: none;}
    
        .content {
          box-shadow: none;
          max-width: 100%;
       }
    
       .login-container .block-flat .content .title { color: #3a3832; }
    }
    </style>
    