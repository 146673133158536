
    import Globals from '../Globals';
    import { Options, Vue } from 'vue-class-component';
    import Axios from 'axios';
    import { API_BASE_URL, BKON_API_TOKEN } from '../environment'
    
    @Options({emits:[]})
    export default class RequestPassword extends Vue {
    
       email:String = '';
       errMessage:String = '';
    
    
    
       //computed
       get logoSrc() { return require('@/assets/logo.png'); }
       get currentYear() {
          return new Date().getFullYear();
       }
    
       //methods
       async sendPasswordResetToken(evt:Event) {
            evt.preventDefault();
            Axios.post(`${API_BASE_URL}/users/password-request-temp`,{email: this.email},{headers: { Authorization: BKON_API_TOKEN }})
            .then(async authResponse=>{
                this.errMessage = "Check your email for a temporary password.";
            })
            .catch(e=>{
                this.errMessage = "Request failed. Please ensure your email is correct and try again.";
            })

       }
    
       //lifecycle
       created() {
          document.body.classList.add('bgColor');
       }
       beforeUnmount() {
          document.body.classList.remove('bgColor');
       }
    }
    