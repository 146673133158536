
import Globals from '../Globals';
import { Options, Vue } from 'vue-class-component';
import Axios from 'axios';
import { API_BASE_URL, BKON_API_TOKEN } from '../environment'

@Options({emits:[]})
export default class Login extends Vue {

   email:String = '';
   password:String = '';
   errMessage:String = '';



   //computed
   get logoSrc() { return require('@/assets/logo.png'); }
   get currentYear() {
      return new Date().getFullYear();
   }

   //methods
   async logUserIn(evt:Event) {
      evt.preventDefault();
      let {email,password} = this;
      Axios.post(`${API_BASE_URL}/users/auth`,{email,password},{headers: { Authorization: BKON_API_TOKEN }})
      .then(async authResponse=>{
         Globals.authenticate(authResponse.data);
         //@ts-ignore
         this.$root.updateMyEmail(email);

         await this.$router.push({name:'Dashboard'}).catch(e=>{});
      })
      .catch(e=>{
         console.error(e,888);
         if(e.response && e.response.data) this.errMessage = e.response.data.failure;
      });
   }

   //lifecycle
   created() {
      document.body.classList.add('bgColor');
   }
   beforeUnmount() {
      document.body.classList.remove('bgColor');
   }
}
