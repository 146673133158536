const RECIPETYPE_TEA = 1;
const RECIPETYPE_COFFEE = 2;
const RECIPETYPE_SPIRITS = 3;
const RECIPETYPE_WINE = 4;
const RECIPETYPE_OTHER = 5;
const RECIPETYPE_INFUSION = 6;

const RECIPESTATUS_APPROVED = {value: 1, title: "Approved"};
const RECIPESTATUS_DEVELOPMENT = {value: 2, title: "In Developement"};
const RECIPESTATUS_REQUESTED = {value: 3, title: "Awaiting Approval"};
const RECIPESTATUS_DISABLED = {value: 4, title: "Disabled"};


const types = {
    [RECIPETYPE_TEA]: 'tea',
    [RECIPETYPE_COFFEE]: 'coffee',
    [RECIPETYPE_SPIRITS]: 'spirit',
    [RECIPETYPE_WINE]: 'wine',
    [RECIPETYPE_OTHER]: 'other',
    [RECIPETYPE_INFUSION]: 'infusion'
};

const statuses = [
    RECIPESTATUS_APPROVED,
    RECIPESTATUS_DEVELOPMENT,
    RECIPESTATUS_REQUESTED,
    RECIPESTATUS_DISABLED
]

export default class Recipe {

   id:number = 0;
   name:string = '';
   dsp_name:string = '';
   description:string = '';
   sequences:any = {};
   image:string = '';
   date:Date = new Date();
   modified:Date = new Date();
   status:string = '';
   notes:string = '';
   account:number = 0;
   code:string = '';
   type:number = 0;
   ingredients:Array<any> = [];
   shared:string = '';
   ingredientsPopup:number = 0;
   format:string = '';
   vacs:string = '';
   temps:string = '';
   waits:string = '';
   appId:string = '';
   user_id:number = 0;
   import:number = 0;

   constructor(opts:any = {}) {
      this.id = opts.id || 0;
      this.name = opts.name || '';
      this.dsp_name = opts.dsp_name || '';
      this.description = opts.description || '';
      this.sequences = opts.sequences || {};
      this.image = opts.image || '';
      this.date = opts.date || new Date();
      this.modified = opts.modified || new Date();
      this.status = opts.status || '';
      this.notes = opts.notes || '';
      this.account = opts.account || 0;
      this.code = opts.code || '';
      this.type = opts.type || '';
      this.ingredients = opts.ingredients || [];
      this.shared = opts.shared || 0;
      this.ingredientsPopup = opts.ingredientsPopup || 0;
      this.format = opts.format || '';
      this.vacs = opts.vacs || '';
      this.temps = opts.temps || '';
      this.waits = opts.waits || '';
      this.appId = opts.appId || '';
      this.user_id = opts.user_id || 0;
      this.import = opts.import || 0;
   }

   update(opts:any) {
      this.id = opts.id || 0;
      this.name = opts.name || '';
      this.dsp_name = opts.dsp_name || '';
      this.description = opts.description || '';
      this.sequences = opts.sequences || '';
      this.image = opts.image || '';
      this.date = opts.date || new Date();
      this.modified = opts.modified || new Date();
      this.status = opts.status || '';
      this.notes = opts.notes || '';
      this.account = opts.account || 0;
      this.code = opts.code || '';
      this.type = opts.type || '';
      this.ingredients = opts.ingredients || '';
      this.shared = opts.shared || 0;
      this.ingredientsPopup = opts.ingredientsPopup || 0;
      this.format = opts.format || '';
      this.vacs = opts.vacs || '';
      this.temps = opts.temps || '';
      this.waits = opts.waits || '';
      this.appId = opts.appId || '';
      this.user_id = opts.user_id || 0;
   }

   static get types() { return types;}
   static get statuses() { return statuses;}
   static get RECIPETYPE_TEA() {return RECIPETYPE_TEA;}
   static get RECIPETYPE_COFFEE() {return RECIPETYPE_COFFEE;}
   static get RECIPETYPE_SPIRITS() {return RECIPETYPE_SPIRITS;}
   static get RECIPETYPE_WINE() {return RECIPETYPE_WINE;}
   static get RECIPETYPE_OTHER() {return RECIPETYPE_OTHER;}
   static get RECIPETYPE_INFUSION() {return RECIPETYPE_INFUSION;}
   static get RECIPESTATUS_APPROVED() {return RECIPESTATUS_APPROVED;}
   static get RECIPESTATUS_DEVELOPMENT() {return RECIPESTATUS_DEVELOPMENT;}
   static get RECIPESTATUS_REQUESTED() {return RECIPESTATUS_REQUESTED;}

}
